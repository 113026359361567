import type { ToastUI } from '~/components/ui/BaseToast.vue'

interface Actions {
  setUI(ui: ToastUI): Actions
  setMessage(msg: string): Actions
  open(): Actions
  close(): Actions
  toggle(): Actions
}

const state = reactive<{
  visible: boolean
  text: string
  ui: ToastUI
  timer: number | null
}>({
  visible: false,
  text: '',
  ui: 'info',
  timer: null,
})

export const useToast = () => {
  const actions: Actions = {
    setUI: (ui) => {
      state.ui = ui
      return actions
    },
    setMessage: (msg) => {
      state.text = msg
      return actions
    },
    open: () => {
      if (state.timer) clearTimeout(state.timer)

      state.visible = true

      state.timer = window.setTimeout(actions.close, 3000)

      return actions
    },
    close: () => {
      if (state.timer) clearTimeout(state.timer)

      state.visible = false

      return actions
    },
    toggle: () => (state.visible ? actions.close() : actions.open()),
  }

  return {
    state,
    actions,
  }
}
