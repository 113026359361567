import { usePersonalStore } from '~/store/personal'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $msalBrowser } = useNuxtApp()

  console.log('01. MSAL middleware')

  await $msalBrowser.state.instance.initialize()

  try {
    await $msalBrowser.state.instance.handleRedirectPromise()
  } catch (error) {
    console.error(error)
  }

  if (to.meta.public) return

  const accounts = await $msalBrowser.state.instance.getAllAccounts()

  $msalBrowser.state.accounts = accounts

  const personalStore = usePersonalStore()

  await personalStore.actions.getProfile()
})
