import { useMembershipsStore } from '~/store/memberships'
import type { AccessManagerUserRoles } from '../../types'

export const useUserRoles = (): ComputedRef<AccessManagerUserRoles> => {
  const membershipsStore = useMembershipsStore()

  const definedRoles = new Map<string, string>([
    ['publisher', 'df95af68-f641-4539-a2c5-072ed6ee14a0'],
    ['themeAdmin', '6073bf47-edad-43b0-b86d-1b86c6e7e640'],
    ['siteAdmin', 'cbb39a69-0fa7-4c41-adab-22a05bc01c76'],
    ['owner', '18e03485-192e-4cf9-bf88-97967498bb08'],
    ['editor', 'ba8e1e4f-a02f-4d40-bc4a-e323619f9a90'],
    ['viewer', '2ebd1c75-9e59-4081-9897-f2f8ea7384bf'],
    ['accessManager', 'ab973278-9ef2-4420-9773-bb65195cb511'],
  ])

  return computed<AccessManagerUserRoles>(() => {
    const include = (role: string): boolean =>
      membershipsStore.state.roles.some(
        ({ id }) => id === definedRoles.get(role)
      )

    return {
      isPublisher: include('publisher'),
      isThemeAdmin: include('themeAdmin'),
      isSiteAdmin: include('siteAdmin'),
      isOwner: include('owner'),
      isEditor: include('editor'),
      isViewer: include('viewer'),
      isAccessManager: include('accessManager'),
    }
  })
}
