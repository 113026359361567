export default defineNuxtRouteMiddleware(async (to) => {
  console.log('03. UnAuthenticationHandler middleware')

  const { $msalBrowser } = useNuxtApp()
  const { isAuthenticated } = $msalBrowser.getters

  if (to.meta.public) return

  const handleUnauthenticated = async (): Promise<
    ReturnType<typeof navigateTo>
  > => {
    const isEmptyIndex = to.fullPath === '/'

    const loginURL = isEmptyIndex ? '/login' : `/login?returnUrl=${to.fullPath}`

    if ($msalBrowser.state.accounts.length) {
      await $msalBrowser.actions.logout({
        postLogoutRedirectUri: loginURL,
      })
    } else {
      return navigateTo(loginURL)
    }
  }

  if (isAuthenticated) {
    try {
      await $msalBrowser.actions.acquireToken()
    } catch (error) {
      return await handleUnauthenticated()
    }
  } else {
    return await handleUnauthenticated()
  }
})
