interface StackElement {
  id: number
  zIndex: number
}

class UIStack {
  private queue: StackElement[] = []

  private get last(): StackElement | null {
    return this.queue[this.queue.length - 1] || null
  }

  public add = (id: number): number => {
    const zIndex = this.last ? this.last.zIndex + 1 : 1000

    this.queue = this.queue.filter((element) => element.id !== id)

    this.queue.push({ id, zIndex })

    return zIndex
  }

  public remove = (id: number): void => {
    this.queue = this.queue.filter((element) => element.id !== id)
  }

  public isLast = (id: number): boolean => this.last?.id === id

  private static instances = 0

  public static getId = (): number => {
    this.instances++
    return this.instances
  }
}

const stack = new UIStack()

interface UseUIStackReturnType {
  add(): number
  delete(): void
  isLast(): boolean
}

export const useUIStack = (): UseUIStackReturnType => {
  const id = UIStack.getId()

  return {
    add: () => stack.add(id),
    delete: () => stack.remove(id),
    isLast: () => stack.isLast(id),
  }
}
