<template>
  <NuxtLayout lightgray>
    <div :class="$style.page">
      <UiBaseIcon :name="content.icon" :class="$style.page__icon" />
      <h1 :class="$style.page__title">
        {{ $t(content.title) }}
      </h1>
      <p :class="$style.page__text">
        {{ $t(content.text) }}
      </p>
      <UiBaseButton
        v-if="content.hasDashboardButton && $accessManager.read.dashboard"
        icon="arrow-right"
        :text="$t('notFound.button')"
        :class="$style.page__button"
        @click="$router.push('/')"
      />
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { defineComponent, computed } from '#imports'

defineComponent({ name: 'ErrorPage' })

const props = defineProps<{ error: { statusCode: number } }>()

const content = computed(() => {
  const code = props.error.statusCode

  interface View {
    icon: string
    title: string
    text: string
    hasDashboardButton: boolean
  }

  const views: Record<number, View> = {
    404: {
      icon: 'compass',
      title: 'notFound.title',
      text: 'notFound.text',
      hasDashboardButton: true,
    },
    403: {
      icon: 'barrier-block',
      title: 'forbiddenAccess.title',
      text: 'forbiddenAccess.text',
      hasDashboardButton: false,
    },
    500: {
      icon: 'barrier-block',
      title: 'internalServerError.title',
      text: 'internalServerError.text',
    },
  }

  return views[code] || views[404]
})
</script>

<style lang="scss" module>
.page {
  display: grid;
  gap: 30px;
  padding: 50px 20px;
  color: var(--text-default);
  text-align: center;
  place-items: center;

  &__icon {
    font-size: 100px;
    stroke-width: 0.75px;
  }

  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.01em;
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
