import { TenantsStoreState } from './types'

export const useTenantsState = (): TenantsStoreState =>
  reactive({
    loaders: {
      tenants: false,
    },
    inited: false,
    tenants: [],
    active: {
      tenantId: '',
      subscriptionId: '',
    },
  })
