import { RedirectRequest } from '@azure/msal-browser'
import { MSALRequests } from '../types'

export const useMsalRequests = (): MSALRequests => {
  const runtimeConfig = useRuntimeConfig()
  const env = runtimeConfig.public
  const scopesDefault = [
    `https://${env.msalDomain}/${env.msalClientId}/${env.msalScope}`,
  ]

  // Add here scopes for id token to be used at MS Identity Platform endpoints.
  const loginRequest: RedirectRequest = {
    authority: `https://${env.msalKnownAuthorities}/${env.msalDomain}/${env.msalSignUpSignInPolicyId}`,
    scopes: scopesDefault,
  }

  const editProfileRequest: RedirectRequest = {
    authority: `https://${env.msalKnownAuthorities}/${env.msalDomain}/${env.msalProfileEditPolicyId}`,
    scopes: scopesDefault,
  }

  return {
    loginRequest,
    editProfileRequest,
  }
}
