import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45msal_45global from "/app/src/middleware/01.msal.global.ts";
import _02_45fed_45auth_45global from "/app/src/middleware/02.fedAuth.global.ts";
import _03_45un_45authentication_45handler_45global from "/app/src/middleware/03.unAuthenticationHandler.global.ts";
import _04_45authentication_45handler_45global from "/app/src/middleware/04.authenticationHandler.global.ts";
import _05_45access_45handler_45global from "/app/src/middleware/05.accessHandler.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45msal_45global,
  _02_45fed_45auth_45global,
  _03_45un_45authentication_45handler_45global,
  _04_45authentication_45handler_45global,
  _05_45access_45handler_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}