import { useMembershipsStore } from '~/store/memberships'
import type { AccessManagerComputedScope, AccessManager } from '../../types'

import { useUserRoles } from './useUserRoles'
import { useReadAccess } from './useReadAccess'
import { useWriteAccess } from './useWriteAccess'

export const useAccessManager = (): AccessManager => {
  const membershipsStore = useMembershipsStore()

  const userRoles = useUserRoles()
  const readAccess = useReadAccess(userRoles)
  const writeAccess = useWriteAccess(userRoles)

  return reactive<{
    read: AccessManagerComputedScope
    write: AccessManagerComputedScope
    init(): Promise<void>
  }>({
    read: readAccess,
    write: writeAccess,
    async init() {
      if (membershipsStore.state.roles.length) return
      await membershipsStore.actions.fetchRoles()
    },
  })
}
