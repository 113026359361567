import { useSharedState } from './state'
import { useSharedGetters } from './getters'
import { useSharedActions } from './actions'

const state = useSharedState()
const getters = useSharedGetters(state)
const actions = useSharedActions(state)

export const useSharedStore = () => ({
  state,
  getters,
  actions,
})
