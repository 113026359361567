<template>
  <UiBaseModal
    :title-icon="icon"
    :title="title || $t('modals.delete.title')"
    :header-class="$style.header"
    @init="onInit"
    @submit="$emit('submit')"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <p>{{ text || $t('modals.delete.text') }}</p>
    <template #footer>
      <UiBaseButton
        ui="transparent"
        id="button_modal_cancel"
        :text="$t('modals.delete.cancel')"
        :disabled="isProcessing"
        @click="cancel"
      />
      <UiBaseButton
        type="submit"
        ui="danger"
        id="button_modal_submit"
        :text="$t('modals.delete.submit')"
        :is-loading="!!isProcessing"
      />
    </template>
  </UiBaseModal>
</template>

<script lang="ts" setup>
import type { UseOpenedActions } from '~/composables/useOpened/types'
import { useOpenedInit } from '~/composables/useOpened'

defineProps<{
  title?: string
  text?: string
  icon?: string
  isProcessing?: boolean
}>()

const emit = defineEmits<{
  (e: 'init', actions: UseOpenedActions): void
  (e: 'submit'): void
  (e: 'cancel'): void
  (e: 'open'): void
  (e: 'close'): void
}>()

const modal = useOpenedInit()

const onInit = (actions: UseOpenedActions): void => {
  modal.onInit(actions)
  emit('init', actions)
}

const cancel = (): void => {
  modal.actions.close()
  emit('cancel')
}
</script>

<style lang="scss" module>
.header {
  color: #fff;
  background: var(--danger-color);
}
</style>
