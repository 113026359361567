import {
  type EventMessage,
  EventMessageUtils,
  EventType,
} from '@azure/msal-browser'
import { useMsalConfig } from './composables/useMsalConfig'
import { useMsalState } from './composables/useMsalState'
import { useMsalRequests } from './composables/useMsalRequests'
import { accountArraysAreEqual } from './utils/accountArraysAreEqual'
import type { MSALActions, MSALGetters } from './types'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useMsalConfig()
  const state = useMsalState(config)
  const requests = useMsalRequests()

  state.instance.addEventCallback((message: EventMessage) => {
    switch (message.eventType) {
      case EventType.ACCOUNT_ADDED:
      case EventType.ACCOUNT_REMOVED:
      case EventType.LOGIN_SUCCESS:
      case EventType.SSO_SILENT_SUCCESS:
      case EventType.HANDLE_REDIRECT_END:
      case EventType.LOGIN_FAILURE:
      case EventType.SSO_SILENT_FAILURE:
      case EventType.LOGOUT_END:
      case EventType.ACQUIRE_TOKEN_SUCCESS:
      case EventType.ACQUIRE_TOKEN_FAILURE: {
        const currentAccounts = state.instance.getAllAccounts()
        if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
          state.accounts = currentAccounts
        }
        break
      }
    }

    const status = EventMessageUtils.getInteractionStatusFromEvent(
      message,
      state.status
    )

    if (status !== null) {
      state.status = status
    }
  })

  const getters: MSALGetters = reactive({
    isAuthenticated: computed(() => !!state.accounts.length),
  })

  const actions: MSALActions = {
    login: (props) =>
      state.instance.loginRedirect({
        ...requests.loginRequest,
        ...(props || {}),
      }),
    logout: (props) => state.instance.logoutRedirect(props),
    editProfile: () =>
      state.instance.acquireTokenRedirect(requests.editProfileRequest),
    acquireToken: () =>
      state.instance.acquireTokenSilent({
        ...requests.loginRequest,
        account: state.accounts[0],
      }),
    getAccessToken: () =>
      actions.acquireToken().then((result) => result.accessToken),
    getRefreshTokens: async () =>
      Object.keys(localStorage).reduce<string[]>((result, key) => {
        const isRefreshToken =
          key.includes(nuxtApp.$config.public.msalKnownAuthorities) &&
          key.includes('refreshtoken')

        if (!isRefreshToken) return result

        const value = localStorage.getItem(key)

        if (!value) return result

        const json = JSON.parse(value)

        const { secret } = json

        if (typeof secret === 'string') result.push(secret)

        return result
      }, []),
  }

  return {
    provide: {
      msalBrowser: {
        state,
        getters,
        actions,
      },
    },
  }
})
