import { SharedStoreState } from './types'
import { getEnvVars } from '~/getEnvVars'

const env = getEnvVars()

export const useSharedState = (): SharedStoreState =>
  reactive({
    applicationId: env.applicationId || '',
    subscriptionId: '',
    subscriptionApplicationId: '',
    tenantId: '',
    processing: {
      sites: {},
      themes: {},
      providers: {},
      apiKeys: {},
      users: {},
    },
  })
