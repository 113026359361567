import { apiSDK } from '~/helpers/apiSDK'
import { PersonalStoreActions, PersonalStoreState } from './types'

export const usePersonalActions = (
  state: PersonalStoreState
): PersonalStoreActions => {
  const actions: PersonalStoreActions = {
    getProfile: async () => {
      if (state.profile) return

      try {
        const response = await apiSDK.identitiesPersonal.getProfile()

        state.profile = response.result || null
      } catch (_) {
        state.profile = null
      }
    },
  }

  return actions
}
