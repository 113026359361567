interface Config {
  applicationId: string
  msalClientId: string
  msalKnownAuthorities: string
  msalDomain: string
  msalScope: string
  msalSignUpSignInPolicyId: string
  identityApiBaseUrl: string
  managementApiBaseUrl: string
}

export const getEnvVars = (): Config => {
  return (
    window.__NUXT__?.config?.public || {
      applicationId: '',
      msalClientId: '',
      msalKnownAuthorities: '',
      msalDomain: '',
      msalScope: '',
      msalSignUpSignInPolicyId: '',
      identityApiBaseUrl: '',
      managementApiBaseUrl: '',
    }
  )
}
