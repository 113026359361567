import { default as choose_45tenantHQunkO6gfTMeta } from "/app/src/pages/choose-tenant.vue?macro=true";
import { default as demoa9Qr9gJPRXMeta } from "/app/src/pages/demo.vue?macro=true";
import { default as fedauthUxsQ4EklaNMeta } from "/app/src/pages/fedauth.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as invitemerRzekj67Meta } from "/app/src/pages/invite.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as addqccPvP6Y8JMeta } from "/app/src/pages/providers/add.vue?macro=true";
import { default as _91slug_93W1Zgwc3dDcMeta } from "/app/src/pages/providers/details/[slug].vue?macro=true";
import { default as details2jSUxSLYZEMeta } from "/app/src/pages/providers/details.vue?macro=true";
import { default as index6fOLR3mExSMeta } from "/app/src/pages/providers/index.vue?macro=true";
import { default as providers8mFh46a1vwMeta } from "/app/src/pages/providers.vue?macro=true";
import { default as api_45keysfOk4dkWnO5Meta } from "/app/src/pages/settings/api-keys.vue?macro=true";
import { default as indexTXO73hmFNkMeta } from "/app/src/pages/settings/index.vue?macro=true";
import { default as userslLpAyJXhZKMeta } from "/app/src/pages/settings/users.vue?macro=true";
import { default as webhooksnvKv6AQ2CBMeta } from "/app/src/pages/settings/webhooks.vue?macro=true";
import { default as settings4uvkikcWGMMeta } from "/app/src/pages/settings.vue?macro=true";
import { default as _91id_93n3L592dMFRMeta } from "/app/src/pages/sites/[id].vue?macro=true";
import { default as indexCSUSruio5rMeta } from "/app/src/pages/sites/index.vue?macro=true";
import { default as sitesQgzgdH9LxnMeta } from "/app/src/pages/sites.vue?macro=true";
import { default as _91id_938DiFCpr1VsMeta } from "/app/src/pages/themes/[id].vue?macro=true";
import { default as indexg1zqxVGcwTMeta } from "/app/src/pages/themes/index.vue?macro=true";
import { default as themesY5azcMRWl9Meta } from "/app/src/pages/themes.vue?macro=true";
export default [
  {
    name: choose_45tenantHQunkO6gfTMeta?.name ?? "choose-tenant",
    path: choose_45tenantHQunkO6gfTMeta?.path ?? "/choose-tenant",
    meta: choose_45tenantHQunkO6gfTMeta || {},
    alias: choose_45tenantHQunkO6gfTMeta?.alias || [],
    redirect: choose_45tenantHQunkO6gfTMeta?.redirect || undefined,
    component: () => import("/app/src/pages/choose-tenant.vue").then(m => m.default || m)
  },
  {
    name: demoa9Qr9gJPRXMeta?.name ?? "demo",
    path: demoa9Qr9gJPRXMeta?.path ?? "/demo",
    meta: demoa9Qr9gJPRXMeta || {},
    alias: demoa9Qr9gJPRXMeta?.alias || [],
    redirect: demoa9Qr9gJPRXMeta?.redirect || undefined,
    component: () => import("/app/src/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: fedauthUxsQ4EklaNMeta?.name ?? "fedauth",
    path: fedauthUxsQ4EklaNMeta?.path ?? "/fedauth",
    meta: fedauthUxsQ4EklaNMeta || {},
    alias: fedauthUxsQ4EklaNMeta?.alias || [],
    redirect: fedauthUxsQ4EklaNMeta?.redirect || undefined,
    component: () => import("/app/src/pages/fedauth.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect || undefined,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invitemerRzekj67Meta?.name ?? "invite",
    path: invitemerRzekj67Meta?.path ?? "/invite",
    meta: invitemerRzekj67Meta || {},
    alias: invitemerRzekj67Meta?.alias || [],
    redirect: invitemerRzekj67Meta?.redirect || undefined,
    component: () => import("/app/src/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: login3hUYZUGxmUMeta?.name ?? "login",
    path: login3hUYZUGxmUMeta?.path ?? "/login",
    meta: login3hUYZUGxmUMeta || {},
    alias: login3hUYZUGxmUMeta?.alias || [],
    redirect: login3hUYZUGxmUMeta?.redirect || undefined,
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    path: providers8mFh46a1vwMeta?.path ?? "/providers",
    children: [
  {
    name: addqccPvP6Y8JMeta?.name ?? "providers-add",
    path: addqccPvP6Y8JMeta?.path ?? "add",
    meta: addqccPvP6Y8JMeta || {},
    alias: addqccPvP6Y8JMeta?.alias || [],
    redirect: addqccPvP6Y8JMeta?.redirect || undefined,
    component: () => import("/app/src/pages/providers/add.vue").then(m => m.default || m)
  },
  {
    name: details2jSUxSLYZEMeta?.name ?? "providers-details",
    path: details2jSUxSLYZEMeta?.path ?? "details",
    children: [
  {
    name: _91slug_93W1Zgwc3dDcMeta?.name ?? "providers-details-slug",
    path: _91slug_93W1Zgwc3dDcMeta?.path ?? ":slug()",
    meta: _91slug_93W1Zgwc3dDcMeta || {},
    alias: _91slug_93W1Zgwc3dDcMeta?.alias || [],
    redirect: _91slug_93W1Zgwc3dDcMeta?.redirect || undefined,
    component: () => import("/app/src/pages/providers/details/[slug].vue").then(m => m.default || m)
  }
],
    meta: details2jSUxSLYZEMeta || {},
    alias: details2jSUxSLYZEMeta?.alias || [],
    redirect: details2jSUxSLYZEMeta?.redirect || undefined,
    component: () => import("/app/src/pages/providers/details.vue").then(m => m.default || m)
  },
  {
    name: index6fOLR3mExSMeta?.name ?? "providers",
    path: index6fOLR3mExSMeta?.path ?? "",
    meta: index6fOLR3mExSMeta || {},
    alias: index6fOLR3mExSMeta?.alias || [],
    redirect: index6fOLR3mExSMeta?.redirect || undefined,
    component: () => import("/app/src/pages/providers/index.vue").then(m => m.default || m)
  }
],
    name: providers8mFh46a1vwMeta?.name ?? undefined,
    meta: providers8mFh46a1vwMeta || {},
    alias: providers8mFh46a1vwMeta?.alias || [],
    redirect: providers8mFh46a1vwMeta?.redirect || undefined,
    component: () => import("/app/src/pages/providers.vue").then(m => m.default || m)
  },
  {
    path: settings4uvkikcWGMMeta?.path ?? "/settings",
    children: [
  {
    name: api_45keysfOk4dkWnO5Meta?.name ?? "settings-api-keys",
    path: api_45keysfOk4dkWnO5Meta?.path ?? "api-keys",
    meta: api_45keysfOk4dkWnO5Meta || {},
    alias: api_45keysfOk4dkWnO5Meta?.alias || [],
    redirect: api_45keysfOk4dkWnO5Meta?.redirect || undefined,
    component: () => import("/app/src/pages/settings/api-keys.vue").then(m => m.default || m)
  },
  {
    name: indexTXO73hmFNkMeta?.name ?? "settings",
    path: indexTXO73hmFNkMeta?.path ?? "",
    meta: indexTXO73hmFNkMeta || {},
    alias: indexTXO73hmFNkMeta?.alias || [],
    redirect: indexTXO73hmFNkMeta?.redirect || undefined,
    component: () => import("/app/src/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: userslLpAyJXhZKMeta?.name ?? "settings-users",
    path: userslLpAyJXhZKMeta?.path ?? "users",
    meta: userslLpAyJXhZKMeta || {},
    alias: userslLpAyJXhZKMeta?.alias || [],
    redirect: userslLpAyJXhZKMeta?.redirect || undefined,
    component: () => import("/app/src/pages/settings/users.vue").then(m => m.default || m)
  },
  {
    name: webhooksnvKv6AQ2CBMeta?.name ?? "settings-webhooks",
    path: webhooksnvKv6AQ2CBMeta?.path ?? "webhooks",
    meta: webhooksnvKv6AQ2CBMeta || {},
    alias: webhooksnvKv6AQ2CBMeta?.alias || [],
    redirect: webhooksnvKv6AQ2CBMeta?.redirect || undefined,
    component: () => import("/app/src/pages/settings/webhooks.vue").then(m => m.default || m)
  }
],
    name: settings4uvkikcWGMMeta?.name ?? undefined,
    meta: settings4uvkikcWGMMeta || {},
    alias: settings4uvkikcWGMMeta?.alias || [],
    redirect: settings4uvkikcWGMMeta?.redirect || undefined,
    component: () => import("/app/src/pages/settings.vue").then(m => m.default || m)
  },
  {
    path: sitesQgzgdH9LxnMeta?.path ?? "/sites",
    children: [
  {
    name: _91id_93n3L592dMFRMeta?.name ?? "sites-id",
    path: _91id_93n3L592dMFRMeta?.path ?? ":id()",
    meta: _91id_93n3L592dMFRMeta || {},
    alias: _91id_93n3L592dMFRMeta?.alias || [],
    redirect: _91id_93n3L592dMFRMeta?.redirect || undefined,
    component: () => import("/app/src/pages/sites/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCSUSruio5rMeta?.name ?? "sites",
    path: indexCSUSruio5rMeta?.path ?? "",
    meta: indexCSUSruio5rMeta || {},
    alias: indexCSUSruio5rMeta?.alias || [],
    redirect: indexCSUSruio5rMeta?.redirect || undefined,
    component: () => import("/app/src/pages/sites/index.vue").then(m => m.default || m)
  }
],
    name: sitesQgzgdH9LxnMeta?.name ?? undefined,
    meta: sitesQgzgdH9LxnMeta || {},
    alias: sitesQgzgdH9LxnMeta?.alias || [],
    redirect: sitesQgzgdH9LxnMeta?.redirect || undefined,
    component: () => import("/app/src/pages/sites.vue").then(m => m.default || m)
  },
  {
    path: themesY5azcMRWl9Meta?.path ?? "/themes",
    children: [
  {
    name: _91id_938DiFCpr1VsMeta?.name ?? "themes-id",
    path: _91id_938DiFCpr1VsMeta?.path ?? ":id()",
    meta: _91id_938DiFCpr1VsMeta || {},
    alias: _91id_938DiFCpr1VsMeta?.alias || [],
    redirect: _91id_938DiFCpr1VsMeta?.redirect || undefined,
    component: () => import("/app/src/pages/themes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexg1zqxVGcwTMeta?.name ?? "themes",
    path: indexg1zqxVGcwTMeta?.path ?? "",
    meta: indexg1zqxVGcwTMeta || {},
    alias: indexg1zqxVGcwTMeta?.alias || [],
    redirect: indexg1zqxVGcwTMeta?.redirect || undefined,
    component: () => import("/app/src/pages/themes/index.vue").then(m => m.default || m)
  }
],
    name: themesY5azcMRWl9Meta?.name ?? undefined,
    meta: themesY5azcMRWl9Meta || {},
    alias: themesY5azcMRWl9Meta?.alias || [],
    redirect: themesY5azcMRWl9Meta?.redirect || undefined,
    component: () => import("/app/src/pages/themes.vue").then(m => m.default || m)
  }
]