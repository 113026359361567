import {
  type Configuration,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser'
import { type MSALState } from '../types'

export const useMsalState = (config: Configuration): MSALState => {
  const instance = new PublicClientApplication(config)

  return shallowReactive<MSALState>({
    status: InteractionStatus.Startup,
    accounts: [],
    instance,
  })
}
