import type {
  AccessManagerComputedScope,
  AccessManagerUserRoles,
} from '../../types'

export const useWriteAccess = (
  roles: ComputedRef<AccessManagerUserRoles>
): AccessManagerComputedScope => {
  return {
    dashboard: computed<boolean>(() => false),
    sites: computed<boolean>(
      () => roles.value.isOwner || roles.value.isSiteAdmin
    ),
    providers: computed<boolean>(
      () => roles.value.isOwner || roles.value.isSiteAdmin
    ),
    themes: computed<boolean>(
      () => roles.value.isOwner || roles.value.isThemeAdmin
    ),
    organization: computed<boolean>(() => roles.value.isOwner),
    users: computed<boolean>(
      () => roles.value.isOwner || roles.value.isAccessManager
    ),
    apiKeys: computed<boolean>(
      () => roles.value.isOwner || roles.value.isAccessManager
    ),
    webhooks: computed<boolean>(() => roles.value.isOwner),
  }
}
