import { useAccessManager } from './composables/useAccessManager'

export default defineNuxtPlugin(() => {
  const accessManager = useAccessManager()

  return {
    provide: {
      accessManager,
    },
  }
})
