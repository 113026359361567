import { useSharedStore } from '../shared'
import { TenantsStoreGetters, TenantsStoreState } from './types'

export const useTenantsGetters = (
  state: TenantsStoreState
): TenantsStoreGetters => {
  const sharedStore = useSharedStore()

  const getters: TenantsStoreGetters = reactive({
    selectedTenant: computed<TenantsStoreGetters['selectedTenant']>(() =>
      state.tenants.find((tenant) => tenant.id === sharedStore.state.tenantId)
    ),

    selectedSubscription: computed<TenantsStoreGetters['selectedSubscription']>(
      () =>
        getters.selectedTenant?.subscriptions?.find(
          (subscription) => subscription.id === sharedStore.state.subscriptionId
        )
    ),
  })

  return getters
}
