import { useSharedStore } from '~/store/shared'
import { useTenantsStore } from '~/store/tenants'

export default defineNuxtRouteMiddleware(async (to) => {
  console.log('04. AuthenticationHandler middleware')

  const { $msalBrowser, $accessManager } = useNuxtApp()
  const { isAuthenticated } = $msalBrowser.getters

  if (to.meta.public && !isAuthenticated) {
    if (to.path.startsWith('/login')) {
      await $msalBrowser.actions.login()
    }

    return
  }

  const sharedStore = useSharedStore()
  const tenantsStore = useTenantsStore()

  const isEmptyTenants = !tenantsStore.state.tenants.length

  if (
    isAuthenticated &&
    isEmptyTenants &&
    !to.meta.withoutRequiredPrerequisites &&
    !to.meta.public
  ) {
    await tenantsStore.actions.fetchTenants()

    if (!tenantsStore.state.tenants.length)
      return showError({ statusCode: 403 })
  }

  await $accessManager.init()

  if (to.meta.public && isAuthenticated) {
    const { returnUrl } = to.query

    const url =
      typeof returnUrl === 'string' && returnUrl.length
        ? returnUrl
        : $accessManager.read.dashboard
        ? '/'
        : $accessManager.read.sites
        ? '/sites/'
        : $accessManager.read.providers
        ? '/providers/'
        : $accessManager.read.themes
        ? '/themes/'
        : $accessManager.read.users
        ? '/settings/users/'
        : $accessManager.read.apiKeys
        ? '/settings/api-keys/'
        : '/'

    return navigateTo(url)
  }

  if (
    !sharedStore.state.subscriptionApplicationId &&
    tenantsStore.state.inited
  ) {
    return showError({ statusCode: 403 })
  }
})
