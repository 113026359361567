<template>
  <Teleport to="body">
    <Transition
      :enter-from-class="$style.modal_inactive"
      :enter-active-class="$style.modal_active"
      :leave-to-class="$style.modal_inactive"
      :leave-active-class="$style.modal_active"
    >
      <div
        v-if="modal.state.isOpened"
        :class="$style.modal"
        :style="{ zIndex: modal.state.zIndex }"
      >
        <form
          ref="containerRef"
          :class="$style.modal__container"
          @submit.prevent="$emit('submit')"
        >
          <div id="modal_header" :class="[$style.modal__header, headerClass]">
            <UiBaseIcon
              v-if="titleIcon"
              :name="titleIcon"
              :class="$style.modal__icon"
            />
            {{ title }}
            <button
              type="button"
              :class="$style.close"
              @click="modal.actions.close"
            >
              <UiBaseIcon name="close" :class="$style.close__icon" />
            </button>
          </div>
          <div :class="$style.modal__body">
            <slot />
          </div>
          <div :class="$style.modal__footer">
            <slot name="footer" />
            <div v-if="dots" :class="$style.dots">
              <div
                v-for="(dot, i) in dots"
                :key="dot"
                :class="[$style.dots__item, i === activeDot && $style.isActive]"
              />
            </div>
          </div>
        </form>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { ref } from '#imports'
import { useOpened } from '~/composables/useOpened'
import type { UseOpenedActions } from '~/composables/useOpened/types'

withDefaults(
  defineProps<{
    title: string
    titleIcon?: string
    headerClass?: string
    dots?: number
    activeDot?: number
  }>(),
  { titleIcon: '', headerClass: undefined, dots: 0, activeDot: 0 }
)

const emit = defineEmits<{
  (e: 'init', actions: UseOpenedActions): void
  (e: 'submit'): void
  (e: 'close'): void
  (e: 'open'): void
}>()

const containerRef = ref<HTMLElement>()

const modal = useOpened({
  preventNodes: () => [containerRef.value],
  onClose: () => emit('close'),
  onOpen: () => emit('open'),
})

emit('init', modal.actions)
</script>

<style lang="scss" module>
.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: auto;
  color: var(--text-default);
  background: rgb(0 0 0 / 0.5);
  inset: 0;

  &_inactive {
    opacity: 0;
  }

  &_active {
    transition: opacity 0.3s;
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 380px;
    margin: auto;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 11px 33px rgba(0, 0, 0, 0.2);

    .modal_inactive & {
      transform: translateY(-40px);
    }

    .modal_active & {
      transition: transform 0.3s;
    }
  }

  &__header {
    margin-bottom: 10px;
    padding: 20px;
    border-bottom: 1px solid #eaeaea;
    border-radius: 16px 16px 0 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.03em;
    text-align: center;
  }

  &__body {
    display: grid;
    gap: 20px;
    padding: 40px 20px;

    p {
      color: var(--text-default);
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 20px 14px;
    border-radius: 0 0 16px 16px;
    column-gap: 20px;
    background: #fafafa;
    row-gap: 5px;
  }

  &__icon {
    font-size: 28px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  color: inherit;
  font-size: 24px;
  background: none;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: -4px 0;
  padding-top: 25px;

  &__item {
    width: 8px;
    height: 8px;
    margin: 4px;
    border-radius: 50%;
    background: #eaeaea;
    transition: background-color 0.3s;

    &.isActive {
      background: var(--accent-color);
    }
  }
}
</style>
