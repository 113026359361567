import { useMembershipsActions } from './actions'
import { useMembershipsState } from './state'

const state = useMembershipsState()

const actions = useMembershipsActions(state)

export const useMembershipsStore = () => ({
  state,
  actions,
})
