import { useTenantsActions } from './actions'
import { useTenantsGetters } from './getters'
import { useTenantsState } from './state'

const state = useTenantsState()

const getters = useTenantsGetters(state)

const actions = useTenantsActions(state, getters)

export const useTenantsStore = () => ({
  state,
  getters,
  actions,
})
