import { Configuration, LogLevel } from '@azure/msal-browser'

export const useMsalConfig = (): Configuration => {
  const runtimeConfig = useRuntimeConfig()
  const env = runtimeConfig.public

  return {
    auth: {
      clientId: env.msalClientId,
      authority: `https://${env.msalKnownAuthorities}/${env.msalDomain}/${env.msalSignUpSignInPolicyId}`,
      redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
      postLogoutRedirectUri: '/login', // Must be registered as a SPA redirectURI on your app registration
      knownAuthorities: [env.msalKnownAuthorities],
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean
        ) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              console.info(message)
              return
            case LogLevel.Verbose:
              console.debug(message)
              return
            case LogLevel.Warning:
              console.warn(message)
              return
            default:
              return
          }
        },
        logLevel: LogLevel.Verbose,
      },
    },
  }
}
