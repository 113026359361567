import { usePersonalActions } from './actions'
import { usePersonalState } from './state'
import { usePersonalGetters } from './getters'

const state = usePersonalState()

const getters = usePersonalGetters(state)

const actions = usePersonalActions(state)

export const usePersonalStore = () => ({
  state,
  getters,
  actions,
})
