import { useOpenedInit } from './useOpened'

interface Actions {
  setId(id: unknown): Actions
  setTitle(title?: string): Actions
  setText(text?: string): Actions
  setIcon(icon?: string): Actions
  startProcessing(id: unknown): Actions
  endProcessing(id: unknown): Actions
  open(submit?: () => unknown, cancel?: () => unknown): Actions
  close(id: unknown): Actions
  reset(): Actions
  isSelected(id: unknown): boolean
}

const state = reactive<{
  id: unknown
  title?: string
  text?: string
  icon?: string
  isProcessing?: boolean
  submit?: () => unknown
  cancel?: () => unknown
}>({
  id: undefined,
  title: undefined,
  text: undefined,
  icon: undefined,
  isProcessing: undefined,
  submit: undefined,
  cancel: undefined,
})

const modal = useOpenedInit()

export const useDeleteConfirmationInit = modal.onInit

export const useDeleteConfirmation = () => {
  const actions: Actions = {
    setId(id) {
      state.id = id
      return actions
    },
    setTitle(title) {
      state.title = title
      return actions
    },
    setText(text) {
      state.text = text
      return actions
    },
    setIcon(icon) {
      state.icon = icon
      return actions
    },
    startProcessing(id) {
      if (actions.isSelected(id)) state.isProcessing = true
      return actions
    },
    endProcessing(id) {
      if (actions.isSelected(id)) state.isProcessing = false
      return actions
    },
    open(submit, cancel) {
      state.submit = submit
      state.cancel = cancel

      modal.actions.open()
      return actions
    },
    close(id) {
      if (actions.isSelected(id)) modal.actions.close()
      return actions
    },
    reset() {
      state.id = undefined
      state.title = undefined
      state.text = undefined
      state.icon = undefined
      state.isProcessing = undefined
      state.submit = undefined
      state.cancel = undefined
      return actions
    },
    isSelected(id) {
      return state.id === id
    },
  }

  return { state, actions }
}
