import { PersonalStoreGetters, PersonalStoreState } from './types'

export const usePersonalGetters = (
  state: PersonalStoreState
): PersonalStoreGetters => {
  const getters: PersonalStoreGetters = reactive({
    userInitials: computed<string>(() => {
      const initials = [
        state.profile?.userProfile?.firstName,
        state.profile?.userProfile?.lastName,
      ]
        .filter(
          (value): value is string =>
            typeof value === 'string' && !!value?.length
        )
        .map((item) => item[0])
        .join('')

      return initials || 'U'
    }),
  })

  return getters
}
