import type { Access } from '~/plugins/accessManager/types'

export default defineNuxtRouteMiddleware(async (to) => {
  console.log('05. AccessHandler middleware')

  const { access } = to.meta

  const isAccess = (list: unknown): list is Access[] =>
    Array.isArray(list) &&
    list.every(
      (item) =>
        isObject<Access>(item) &&
        Object.hasOwn(item, 'scope') &&
        Object.hasOwn(item, 'actions') &&
        item.actions.every((action) => ['read', 'write'].includes(action))
    )

  if (isAccess(access)) {
    const { $accessManager } = useNuxtApp()

    const hasAccess = access.some(({ scope, actions }) =>
      actions.some((action) => {
        const hasAccess = $accessManager[action][scope]
        return hasAccess
      })
    )

    if (!hasAccess) {
      return showError({ statusCode: 403 })
    }
  }
})
