import { SharedStoreActions, SharedStoreState } from './types'

export const useSharedActions = (
  state: SharedStoreState
): SharedStoreActions => {
  const actions: SharedStoreActions = {
    startProcessing(entityType, processingType, id) {
      const states = state.processing[entityType][id] || {
        deleting: false,
        updating: false,
      }

      state.processing[entityType][id] = {
        ...states,
        [processingType]: true,
        get processing() {
          return this.deleting || this.updating
        },
      }
    },
    endProcessing(entityType, processingType, id) {
      state.processing[entityType][id] = {
        ...state.processing[entityType][id],
        [processingType]: false,
        get processing() {
          return this.deleting || this.updating
        },
      }

      if (!state.processing[entityType][id].processing) {
        delete state.processing[entityType][id]
      }
    },
  }

  return actions
}
