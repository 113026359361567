import {
  SubscriptionResponseExtended,
  TenantResponseExtended,
} from 'identity-api-client'
import { apiSDK } from '~/helpers/apiSDK'
import { useSharedStore } from '~/store/shared'
import {
  TenantsStoreActions,
  TenantsStoreGetters,
  TenantsStoreState,
} from './types'

const selectedTenant = 'selectedTenant'
const selectedSubscription = 'selectedSubscription'

export const useTenantsActions = (
  state: TenantsStoreState,
  getters: TenantsStoreGetters
): TenantsStoreActions => {
  const sharedStore = useSharedStore()

  const actions: TenantsStoreActions = {
    async fetchTenants() {
      if (state.loaders.tenants) return

      try {
        state.loaders.tenants = true

        const response =
          await apiSDK.tenantsPersonal.getTenantsExtendedForCurrentIdentity(
            true,
            true,
            true
          )

        if (Array.isArray(response.result)) {
          // remove all tenants, subscriptions, and subscriptionApplications that relates for the another application based on application.id
          state.tenants = response.result.reduce<TenantResponseExtended[]>(
            (tenants, tenant) => {
              const subscriptions = Array.isArray(tenant.subscriptions)
                ? tenant.subscriptions.reduce<SubscriptionResponseExtended[]>(
                    (subscriptions, subscription) => {
                      const subscriptionApplications = Array.isArray(
                        subscription.subscriptionApplications
                      )
                        ? subscription.subscriptionApplications.filter(
                            (subscriptionApplication) =>
                              subscriptionApplication.application?.id ===
                              sharedStore.state.applicationId
                          )
                        : []

                      if (subscriptionApplications.length)
                        subscriptions.push({
                          ...subscription,
                          subscriptionApplications,
                        })

                      return subscriptions
                    },
                    []
                  )
                : []

              if (subscriptions.length)
                tenants.push({
                  ...tenant,
                  subscriptions,
                })

              return tenants
            },
            []
          )
        } else {
          state.tenants = []
        }

        actions.init()
      } catch (error) {
        console.warn(error)
        state.tenants = []
      } finally {
        state.loaders.tenants = false
        state.inited = true
      }
    },

    init() {
      if (!state.tenants.length) throw new Error('no tenants')

      const tenantId = localStorage.getItem(selectedTenant)
      const subscriptionId = localStorage.getItem(selectedSubscription)

      if (
        !tenantId ||
        !state.tenants.some((tenant) => tenant.id === tenantId)
      ) {
        const tenant =
          state.tenants.find((tenant) => tenant.isEnabled) || state.tenants[0]

        const id = tenant.id

        if (!id) return

        localStorage.setItem(selectedTenant, id)

        sharedStore.state.tenantId = id
      } else {
        sharedStore.state.tenantId = tenantId
      }

      const tenant = getters.selectedTenant

      if (
        !subscriptionId ||
        !tenant?.subscriptions?.some(
          (subscription) => subscription.id === subscriptionId
        )
      ) {
        if (!tenant?.subscriptions) return

        const subscription =
          tenant.subscriptions.find((subscription) => subscription.isEnabled) ||
          tenant.subscriptions[0]

        const id = subscription.id

        if (!id) return

        localStorage.setItem(selectedSubscription, id)

        sharedStore.state.subscriptionId = id
      } else {
        sharedStore.state.subscriptionId = subscriptionId
      }

      actions.updateSubscriptionApplicationId()
    },

    select(tenantId, subscriptionId) {
      sharedStore.state.tenantId = tenantId
      sharedStore.state.subscriptionId = subscriptionId
      localStorage.setItem(selectedTenant, tenantId)
      localStorage.setItem(selectedSubscription, subscriptionId)

      actions.updateSubscriptionApplicationId()
    },

    updateSubscriptionApplicationId() {
      const subscriptionApplication =
        getters.selectedTenant?.subscriptions?.find(
          (subscription) => subscription.id === sharedStore.state.subscriptionId
        )?.subscriptionApplications?.[0]

      if (subscriptionApplication)
        sharedStore.state.subscriptionApplicationId =
          subscriptionApplication.id || ''
    },
  }

  return actions
}
