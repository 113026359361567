import { reactive } from '#imports'
import { MembershipsStoreState } from './type'

export const useMembershipsState = (): MembershipsStoreState =>
  reactive({
    loaders: {
      fetchRoles: false,
    },
    roles: [],
  })
