<template>
  <svg viewBox="0 0 24 24" :class="[$style.icon, fluid && $style.icon_fluid]">
    <use :xlink:href="url" />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from '#imports'

const props = defineProps<{
  name: string
  fluid?: boolean
}>()

const url = computed(() => `/images/sprite.svg#${props.name}`)
</script>

<style lang="scss" module>
.icon {
  display: inline-block;
  aspect-ratio: 1/1;
  vertical-align: top;
  fill: none;
  stroke: currentColor;
  stroke-width: 1.5px;
  stroke-linecap: round;
  stroke-linejoin: round;

  &:not(.icon_fluid) {
    height: 1em;
  }

  &_fluid {
    width: 100%;
  }
}
</style>
