import type {
  AccessManagerComputedScope,
  AccessManagerUserRoles,
} from '../../types'

export const useReadAccess = (
  roles: ComputedRef<AccessManagerUserRoles>
): AccessManagerComputedScope => {
  return {
    dashboard: computed<boolean>(
      () =>
        roles.value.isOwner ||
        roles.value.isSiteAdmin ||
        roles.value.isThemeAdmin ||
        roles.value.isViewer ||
        roles.value.isAccessManager
    ),
    sites: computed<boolean>(
      () =>
        roles.value.isOwner || roles.value.isSiteAdmin || roles.value.isViewer
    ),
    providers: computed<boolean>(
      () =>
        roles.value.isOwner || roles.value.isSiteAdmin || roles.value.isViewer
    ),
    themes: computed<boolean>(
      () =>
        roles.value.isOwner ||
        roles.value.isSiteAdmin ||
        roles.value.isThemeAdmin ||
        roles.value.isViewer
    ),
    organization: computed<boolean>(
      () =>
        roles.value.isOwner || roles.value.isSiteAdmin || roles.value.isViewer
    ),
    users: computed<boolean>(
      () =>
        roles.value.isOwner ||
        roles.value.isAccessManager ||
        roles.value.isSiteAdmin ||
        roles.value.isViewer
    ),
    apiKeys: computed<boolean>(
      () => roles.value.isOwner || roles.value.isAccessManager
    ),
    webhooks: computed<boolean>(() => roles.value.isOwner),
  }
}
