import * as IdentitySDK from 'identity-api-client'
import * as ManagementSDK from '@perfectiondev/oversite-management-api-client'
import { isObject } from '~/utils/isObject'
import { getEnvVars } from '~/getEnvVars'

const env = getEnvVars()

export const apiSDK = new IdentitySDK.identityApi({
  BASE: env.identityApiBaseUrl,
  HEADERS: async (options) => {
    const { $msalBrowser } = useNuxtApp()
    return {
      ...(options.headers || {}),
      authorization: 'Bearer ' + (await $msalBrowser.actions.getAccessToken()),
    }
  },
})

export const managementSDK = new ManagementSDK.oversiteManagementApi({
  BASE: env.managementApiBaseUrl,
  HEADERS: async (options) => {
    const { $msalBrowser } = useNuxtApp()
    return {
      ...(options.headers || {}),
      authorization: 'Bearer ' + (await $msalBrowser.actions.getAccessToken()),
    }
  },
})

interface ManagementCommonError extends ManagementSDK.ApiError {
  body: string
}

export const SDKErrors = {
  management: {
    isCommon: (error: unknown): error is ManagementCommonError => {
      if (
        !(error instanceof ManagementSDK.ApiError) ||
        typeof error.body !== 'string'
      )
        return false

      const body = JSON.parse(error.body)

      return isObject<{ detail: string }>(body) && !!body.detail
    },
    getCommon: (error: ManagementCommonError): string => {
      const body = JSON.parse(error.body) as { detail: string }
      return body.detail
    },
  },
}
