import { ApiError } from 'identity-api-client'
import { apiSDK } from '~/helpers/apiSDK'

export default defineNuxtRouteMiddleware(async (to) => {
  console.log('02. FedAuth middleware')

  if (!to.fullPath.startsWith('/fedauth')) return

  const { $msalBrowser } = useNuxtApp()

  if ($msalBrowser.getters.isAuthenticated) {
    try {
      const [refreshToken] = await $msalBrowser.actions.getRefreshTokens()

      if (!refreshToken) throw new Error('need to refresh')

      if (typeof to.query.returnUrl === 'string') {
        const { result } =
          await apiSDK.tokensPersonal.getRefreshedTokensForIdentityAsync({
            refreshToken,
          })

        const returnURL = new URL(to.query.returnUrl)

        returnURL.searchParams.append('token', result.accessToken)
        returnURL.searchParams.append('refresh', result.refreshToken)

        return navigateTo(returnURL.toString(), { external: true })
      } else {
        return navigateTo('/', { external: true })
      }
    } catch (error) {
      if (error instanceof ApiError && error.status === 500) {
        showError({ statusCode: 500 })
      } else {
        await $msalBrowser.actions.logout({
          postLogoutRedirectUri: to.fullPath,
        })
      }
    }
  }
})
