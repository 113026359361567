<template>
  <div id="toasts" :class="$style.toasts">
    <UiBaseToast
      v-model="toast.state.visible"
      no-teleport
      :ui="toast.state.ui"
      :text="toast.state.text"
    />
  </div>
  <ModalsModalConfirm
    :title="deleteConfirmation.state.title"
    :text="deleteConfirmation.state.text"
    :icon="deleteConfirmation.state.icon"
    :is-processing="deleteConfirmation.state.isProcessing"
    @init="useDeleteConfirmationInit"
    @close="deleteConfirmation.actions.reset"
    @cancel="deleteConfirmation.state.cancel"
    @submit="deleteConfirmation.state.submit"
  />
  <NuxtLayout>
    <div>
      <NuxtPage />
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { useHead } from '#imports'
import { useI18n } from 'vue-i18n'
import { useToast } from '~/composables/useToast'
import {
  useDeleteConfirmation,
  useDeleteConfirmationInit,
} from '~/composables/useDeleteConfirmation'

const toast = useToast()

const deleteConfirmation = useDeleteConfirmation()

const { t } = useI18n()

useHead({
  titleTemplate: (title) => t('seo.titleTemplate', { title: title || '...' }),
})
</script>

<style lang="scss" module>
.toasts {
  position: fixed;
  z-index: 9999;
  inset: auto 10px 10px;
  display: grid;
  gap: 10px;
  pointer-events: none;

  > * {
    margin: auto;
    pointer-events: auto;
  }
}
</style>
