import type { ExternalLinks } from './types'

export default defineNuxtPlugin(() => {
  const externalLinks: ExternalLinks = {
    docs: 'https://docs.perfection.dev/',
    privacyPolicy: 'https://www.perfection.dev/privacy-policy.html',
    terms: 'https://www.perfection.dev/terms.html',
    linkedIn: 'https://www.linkedin.com/company/perfection-dev/',
    twitter: 'https://twitter.com/PerfectionDev',
  }

  return {
    provide: {
      externalLinks,
    },
  }
})
