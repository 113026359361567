import { useSharedStore } from '~/store/shared'
import { MembershipsStoreActions, MembershipsStoreState } from './type'
import { apiSDK } from '~/helpers/apiSDK'

export const useMembershipsActions = (
  state: MembershipsStoreState
): MembershipsStoreActions => {
  const sharedStore = useSharedStore()

  const actions: MembershipsStoreActions = {
    async fetchRoles() {
      if (
        state.loaders.fetchRoles ||
        !sharedStore.state.subscriptionApplicationId
      )
        return

      try {
        state.loaders.fetchRoles = true

        const response =
          await apiSDK.membershipsPersonal.getMembershipsForSubscriptionApplication(
            sharedStore.state.subscriptionApplicationId
          )

        state.roles = response.result?.roles || []
        state.loaders.fetchRoles = false
      } catch (error) {
        state.roles = []
        state.loaders.fetchRoles = false
      }
    },
  }

  return actions
}
