<template>
  <Teleport
    :to="isMounted && !noTeleport ? '#toasts' : null"
    :disabled="!isMounted || noTeleport"
  >
    <Transition
      :enter-from-class="$style.toast_inactive"
      :enter-active-class="$style.toast_active"
      :leave-active-class="$style.toast_active"
      :leave-to-class="$style.toast_inactive"
    >
      <div
        v-if="toast.state.isOpened"
        :class="[$style.toast, $style[`toast_ui-${ui}`]]"
      >
        <UiBaseIcon :name="icon" :class="$style.toast__icon" />
        <div :class="$style.toast__text">
          {{ text }}
        </div>
        <button
          type="button"
          :class="$style.close"
          @click="toast.actions.close"
        >
          <UiBaseIcon name="close" :class="$style.close__icon" />
        </button>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed, watch } from '#imports'
import { useOpened } from '~/composables/useOpened'
import { useIsMounted } from '~/composables/useIsMounted'

export type ToastUI = 'success' | 'danger' | 'warning' | 'info'

const props = defineProps<{
  modelValue: boolean
  noTeleport?: boolean
  autoClose?: boolean | number
  text: string
  ui: ToastUI
}>()

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()

const isMounted = useIsMounted()

const icon = computed(() => {
  const icons: Record<ToastUI, string> = {
    success: 'check',
    danger: 'alert-octagon',
    warning: 'alert-triangle',
    info: 'info-circle',
  }

  return icons[props.ui]
})

const toast = useOpened({
  closeOnOutsideClick: false,
  onOpen() {
    if (props.autoClose) {
      clearTimeout(timer)

      const delay =
        typeof props.autoClose === 'number' && isFinite(props.autoClose)
          ? props.autoClose
          : 3000

      timer = window.setTimeout(() => {
        toast.actions.close()
      }, delay)
    }
  },
  onClose() {
    clearTimeout(timer)
    emit('update:modelValue', false)
  },
})

let timer: number

watch(
  () => props.modelValue,
  (isOpened) => {
    if (isOpened === toast.state.isOpened) return

    if (isOpened) toast.actions.open()
    else toast.actions.close()
  }
)
</script>

<style lang="scss" module>
.close {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-top: 4px;
  color: inherit;
  text-align: left;
  background: none;

  &__icon {
    font-size: 20px;
  }
}

.toast {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 12px 24px;
  border-radius: 26px;
  overflow: hidden;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  &_active {
    transition: opacity 0.3s, transform 0.3s;
  }

  &_inactive {
    transform: translateY(20px);
    opacity: 0;
  }

  &_ui-success {
    background: var(--success-color);
  }

  &_ui-danger {
    background: var(--notification-color);
  }

  &_ui-warning {
    background: var(--warning-color);
  }

  &_ui-info {
    background: var(--info-color);
  }

  &__icon {
    flex: 0 0 auto;
    font-size: 28px;
  }

  &__text {
    flex: 1 1 auto;
    padding: 2px 10px;
  }
}
</style>
