import { SharedStoreGetters, SharedStoreState } from './types'

export const useSharedGetters = (
  state: SharedStoreState
): SharedStoreGetters => {
  const getters: SharedStoreGetters = reactive({
    getProcessing: computed<SharedStoreGetters['getProcessing']>(() => {
      return (entityType, id) => {
        const defaultStates: ReturnType<SharedStoreGetters['getProcessing']> = {
          deleting: false,
          updating: false,
          get processing() {
            return this.deleting || this.updating
          },
        }

        if (typeof id === 'string')
          return state.processing[entityType][id] || defaultStates

        return defaultStates
      }
    }),
  })

  return getters
}
