import { reactive, watchEffect, onBeforeUnmount } from '#imports'
import { isClosestNode } from '~/utils/isClosestNode'
import type {
  UseOpenedParameters,
  UseOpenedReturnType,
  UseOpenedActions,
  UseOpenedInitReturnType,
} from './types'

import { useUIStack } from '~/composables/useUIStack'

export const useOpenedInit = (): UseOpenedInitReturnType => {
  const actionPlaceholder = (): void =>
    console.warn('please init the actions before call')

  const actions = reactive({
    open: actionPlaceholder,
    close: actionPlaceholder,
    toggle: actionPlaceholder,
  })

  const onInit = (e: UseOpenedActions): void => {
    actions.open = e.open
    actions.close = e.close
    actions.toggle = e.toggle
  }

  return { actions, onInit }
}

export const useOpened = ({
  closeOnOutsideClick = true,
  closeOnEscape = true,
  withStack = true,
  preventNodes,
  onClose,
  onOpen,
}: UseOpenedParameters = {}): UseOpenedReturnType => {
  const stack = useUIStack()

  const state = reactive({ isOpened: false, zIndex: 0 })

  const open = (): void => {
    if (state.isOpened) return
    if (typeof onOpen === 'function') onOpen()

    if (withStack) state.zIndex = stack.add()

    state.isOpened = true
  }

  const close = (): void => {
    if (!state.isOpened) return
    if (typeof onClose === 'function') onClose()

    if (withStack) stack.delete()

    state.isOpened = false
  }

  const toggle = (): void => (state.isOpened ? close() : open())

  const onDocumentKeyUp = (e: Event): void => {
    if (
      !(e instanceof KeyboardEvent) ||
      e.key !== 'Escape' ||
      (withStack && !stack.isLast())
    )
      return

    close()
  }

  let target: EventTarget | null = null

  const onDocumentPointerDown = (e: Event): void => {
    target = e.target
  }

  const onDocumentPointerUp = (e: Event): void => {
    if (target !== e.target) return

    target = null

    if (
      (typeof preventNodes === 'function' &&
        preventNodes().some((node) => isClosestNode(node, e.target))) ||
      (withStack && !stack.isLast())
    )
      return

    close()
  }

  if (closeOnOutsideClick && process.client) {
    watchEffect(() => {
      if (state.isOpened) {
        document.addEventListener('pointerdown', onDocumentPointerDown, true)
        document.addEventListener('pointerup', onDocumentPointerUp, true)
      } else {
        document.removeEventListener('pointerdown', onDocumentPointerDown, true)
        document.removeEventListener('pointerup', onDocumentPointerUp, true)
      }
    })
  }

  if (closeOnEscape && process.client) {
    watchEffect(() => {
      if (state.isOpened) {
        document.addEventListener('keyup', onDocumentKeyUp, true)
      } else {
        document.removeEventListener('keyup', onDocumentKeyUp, true)
      }
    })
  }

  onBeforeUnmount(() => {
    document.removeEventListener('pointerdown', onDocumentPointerDown, true)
    document.removeEventListener('pointerup', onDocumentPointerUp, true)
    document.removeEventListener('keyup', onDocumentKeyUp, true)
    stack.delete()
  })

  return {
    state,
    actions: { open, close, toggle },
  }
}
