export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"theme-color","media":"(prefers-color-scheme: light)","content":"#ffffff"},{"name":"theme-color","media":"(prefers-color-scheme: dark)","content":"#005f73"}],"link":[{"data-style":"fonts","rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"},{"rel":"icon","type":"image/png","href":"/favicon/favicon.ico","sizes":"any"},{"rel":"icon","type":"image/svg+xml","href":"/favicon/favicon.svg"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-touch-icon.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"preconnect","href":"https://fonts.gstatic.com"},{"rel":"preconnect","href":"https://fonts.googleapis.com"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'